<template>
  <div class="custom-list">
    <div class="row mb-5 mt-5 justify-content-between w-100">
      <div class="col-6">
        <input
          placeholder="Ara.."
          type="text"
          class="form-control"
          v-model="search"
        />
      </div>
      <button @click="addFileModal = true" class="btn btn-main-success">
        Ekle
      </button>

      <b-modal v-model="addFileModal" title="Teknik Destek Talep Ekle">
        <div class="form-group">
          <label for="">Talep Adı</label>
          <input
            class="w-100 mt-3"
            type="text"
            style="
              border: 1px solid rgba(128, 128, 128, 0.521);
              height: 35px;
              border-radius: 5px;
            "
          />
        </div>
        <div class="form-group">
          <label for="">Talep Dosyası Ekle</label>
          <b-form-file
            v-model="file1"
            placeholder="Dosya Ekleyiniz"
            drop-placeholder="Drop file here..."
            class="mt-5"
          ></b-form-file>
        </div>

        <template #modal-footer>
          <button class="btn btn-secondary" @click="addFileModal = false">
            Vazgeç
          </button>
          <button class="btn btn-success" @click="addFileModal = false">
            Ekle
          </button>
        </template>
      </b-modal>

    </div>
    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="Teknik Destek"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.action="{ item }">
        <div>
          <b-icon
            icon="trash-fill"
            aria-hidden="true"
            class="trashIcon"
            @click="dosyaSil(item)"
          ></b-icon>
        </div>
      </template>
      <template v-slot:item.dosya="{ item }">
        <a :href="gorsel" target="_blank">Dosyayı Görüntüle</a>
      </template>
      <template v-slot:item.durumListesi="{ item }">
        <div v-html="getIsActiveBadge(item.isActive)"></div>
      </template>
      <template v-slot:item.isortagi="{ item }">
          <div>
              <p class="text-dark">{{ item.IsOrtagiAdi?.SorumluKisi }} </p> 
          </div>
        </template>
    </List>
    <!-- Create and ısOrtagiCalisanAdis Popups -->
  </div>
</template>
<script>
export default {
  name: "customerList",

  data() {
    return {
      gorsel: "https://picsum.photos/id/237/200/300",
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      badgesEnum: ["green", "yellow", "red"],
      stepNames: ["Hazırlık", "Satış", "Devir"],
      header: [
        { text: "", value: "id", checkboxField: true},
        { text: "İş Ortağı Adı", sortable: true, value: "isortagi",size:"100px"},
        { text: "İş Ortağı Çalışan Adı",value: "isOrtagiCalisanAdi",size: "200px",},
        { text: "Fiyat", value: "fiyat" },
        { text: "Para Birimi", value: "paraBirimi" },
        { text: "Açıklama", value: "aciklama" },
        { text: "Süre", value: "sure" },
        { text: "Teklif Durumu", value: "durumListesi"},
        { text: "Ret Açıklaması", value: "retAciklamasi"},
        { text: "İşlemler", value: "action"},
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      filterList: [],
      list_length: 5,
      addFileModal: false,
      durumListesi: [
        {
          id: 1,
          isim: "Red",
          badge: "badge-success",
          
        },
        {
          id: 2,
          isim: "Bekliyor",
          badge: "badge-info",
        },
        {
          id: 5,
          isim: "Onaylandı",
          badge: "badge-is",
        },
      ],
    };
  },

  created() {
    this.search = this.$route.query.search;
    this.search = this.search.toLowerCase();
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.items = [
        {
          id: 1,
          isOrtagiAdi: "Melisa Eldem",
          isOrtagiCalisanAdi: "İş Ortağı Çalışan Adı",
          fiyat: "400",
          paraBirimi: "Türk Lirası",
          aciklama: "lorem ipsum dolor sit amet.",
          sure: "20.12.2029",
          retAciklamasi: "lorem ipsum dolor sit amet.",
          isActive: 1,
        },
        {
          id:2,
          isOrtagiAdi: "Refik Karadeniz",
          isOrtagiCalisanAdi: "İş Ortağı Çalışan Adı",
          fiyat: "400",
          paraBirimi: "Türk Lirası",
          aciklama: "lorem ipsum dolor sit amet.",
          sure: "20.12.2029",
          retAciklamasi: "-",
          isActive: 2,
        },
        {
          id:3,
          isOrtagiAdi: "Ahmet Berke",
          isOrtagiCalisanAdi: "İş Ortağı Çalışan Adı",
          fiyat: "400",
          paraBirimi: "Türk Lirası",
          aciklama: "lorem ipsum dolor sit amet.",
          sure: "20.12.2029",
          retAciklamasi: "-.",
          isActive: 5,
        },
      ];
      this.totalItems = this.items.length;

      this.pagination = {
        current_page: 1,
        total_pages: Math.ceil(this.items?.length / this.list_length),
        total_items: this.items.length,
        list_length: this.list_length,
      };
      this.loading = false;
      if (this.search) {
        const searchValue = this.search;
        const foundItems = this.items.filter((item) => {
          for (const prop in item) {
            if (item[prop].toString().toLowerCase().includes(searchValue)) {
              return true;
            }
          }
          return false;
        });
        this.items = foundItems;
      }
    },
    dosyaSil(item) {
      this.items = this.items.filter((i) => i.id !== item.id);
    },
    getIsActiveBadge(item) {
      const activeStatus = this.durumListesi.find((durum) => durum.id === item);
      return `<span class="badge  rounded-sm  badge-${activeStatus.id}" >${activeStatus.isim}</span>`;
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function () {
      this.getList();
    },
    list_length: function () {
      this.getList();
    },
    $route: function () {
      this.search = this.$route.query.search;
      this.search = this.search.toLowerCase();
    },
  },
};
</script>

<style lang="scss">
.nav-tabs {
  border-bottom: 0 !important;
  div {
    width: 100%;

    li {
      flex: 1;

      a {
        display: flex;
        justify-content: center;
        margin: 0 auto !important;
        font-weight: 600;
        letter-spacing: 0.5px;
        font-size: 1rem;
        color: $dveb-text-color !important;
        border-bottom: 5px solid $dveb-main-gray !important;

        &.active {
          border-bottom: 5px solid $dveb-main-red !important;
        }
      }
    }
  }
}

.tab-content {
  width: 100%;
}

.tab-area {
  display: flex;
  flex-direction: column;

  .tab-content {
    display: flex;
    align-items: center;

    img {
      background-color: red;
      border-radius: 50%;
      margin-right: 3rem;
      border: 2px solid $dveb-main-red;
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.custom-pagination {
  div {
    width: auto !important;
  }
}
.trashIcon {
  color: $dveb-main-red;
  font-size: 18px;
}
</style>
